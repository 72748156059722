import React, { useContext } from 'react';
import { Link, graphql } from 'gatsby';
import LazyImage from 'gatsby-image';
import {
  Button,
  Card,
  CardContent,
  CardImage,
  Column,
  Columns,
  Container,
  Content,
  Hero,
  HeroBody,
  LevelLeft,
  LevelItem,
  LevelRight,
  Message,
  MessageHeader,
  MessageBody,
  Title,
  Subtitle
} from 'bloomer';
import Typist from 'react-typist';
import Fade from 'react-reveal/Fade';

// Components
import Layout from '../components/layout';
import SEO from '../components/seo';
import Animated from '../components/animated';

// Theme
import { ThemeContext, getOppositeTheme } from '../contexts/theme';
// import { MessageHeader } from 'bloomer/lib/components/Message/MessageHeader';
// import { MessageBody } from 'bloomer/lib/components/Message/MessageBody';
// import { LevelLeft } from 'bloomer/lib/components/Level/LevelLeft';

const BlogIndex = props => {
  const { theme } = useContext(ThemeContext);

  const { data, location } = props;
  const { title: siteTitle, siteDomain, keywords } = data.site.siteMetadata;
  const posts = data.allMarkdownRemark.edges;

  return (
    <Layout location={location}>
      <SEO title={siteTitle} keywords={keywords || []} />
      <Hero isColor={theme} className="is-fullheight-with-navbar">
        <HeroBody>
          <Container>
            <Columns>
              <Column>
                <Fade left>
                <React.Fragment>
                    <Title>{siteDomain}</Title>
                    <Subtitle>
                      <Typist startDelay={2000}>
                      八王子、人と動物
                        <span role="img" aria-label="jsx-a11y/accessible-emoji">
                        🐶🐱
                        </span>にやさしい街{' '}
                      </Typist>
                    </Subtitle>
                  </React.Fragment>
                </Fade>
                <p/>
                <p/>
                <Container style={{ marginTop: 50 }}>
                  <Columns isCentered>
                    <Column>
                    <Link to="/members">
                      <Button isColor="danger">サービスへサインインする</Button>
                    </Link>
                    </Column>
                  </Columns>

                </Container>
              </Column>
              <Column>
              <Message isColor="info">
                    <MessageHeader>
                      <LevelLeft><LevelItem>更新しました</LevelItem></LevelLeft>
                      <LevelRight><LevelItem>2019/10/8</LevelItem></LevelRight>
                    </MessageHeader>
                    
                    <MessageBody>
                    日付選択の処理を更新しました。これで完成形となります、ご確認よろしくお願いします。
                    </MessageBody>
                  </Message>
              <Message isColor="info">
                    <MessageHeader>
                      <LevelLeft><LevelItem>テスト運用開始</LevelItem></LevelLeft>
                      <LevelRight><LevelItem>2019/10/4</LevelItem></LevelRight>
                    </MessageHeader>
                    
                    <MessageBody>
                    第一弾、会計報告書作成サービスをベータ版をリリースしました。
                    </MessageBody>
                  </Message>
                <Message isColor="info">
                    <MessageHeader>
                      <LevelLeft><LevelItem>サービス開始予定</LevelItem></LevelLeft>
                      <LevelRight><LevelItem>2019/10/21</LevelItem></LevelRight>
                    </MessageHeader>
                    
                    <MessageBody>
                    第一弾、会計報告書作成サービスを2019/10/21にリリースを予定しています。
                    </MessageBody>
                  </Message>
                </Column>
              </Columns>
          </Container>
        </HeroBody>
      </Hero>

    </Layout>
  );
};

export default BlogIndex;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        siteDomain
        keywords
      }
    }
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { draft: { ne: true } } }
    ) {
      edges {
        node {
          timeToRead
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            description
            tags
            cover {
              childImageSharp {
                fluid(maxHeight: 180, quality: 80) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    }
  }
`;
